<template>
    <base-members
        :group="group"
        :role="role"
        search
        show-since
    >
        <template #title>
            {{ $t('groups.clients.title') }}
        </template>
    </base-members>
</template>

<script>
import Role from '@/library/enumerations/Role';
import BaseMembers from '@/pages/groups/BaseMembers';
import {Group} from '@/models/vue-mc/Group';

export default {
    name: 'GroupClients',
    components: {BaseMembers},
    props: {
        group: {
            type: Group,
            required: true,
        },
    },
    data: () => ({
        role: Role.CLIENT,
    }),
};
</script>
